export const prezzoFormattato = function(value) {
    if(typeof value == 'number'){
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    } else {
        return "---";
    }
}

export const prezzoFormattatoStripe = function(value) {
    if(typeof value == 'number'){
        let length = prezzo.length;
        let breack = (length - 2);
        let prezzo_intero = prezzo.slice(0, breack);
        let prezzo_decimali = prezzo.slice(breack, 2);
        let prezzo_elab = prezzo_intero+"."+prezzo_decimali;
        let val = (prezzo_elab/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    } else {
        return "---";
    }
}

export const statoRimborso = function(value) {
    if(value == 'pending'){
        return 'In Corso';
    } else if(value == 'succeeded'){
        return "Rimborsato";
    } else {
        return "Fallito";
    }
}